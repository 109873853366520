let connectWiseClientApi;

document.addEventListener("DOMContentLoaded", function (event) {
   onReady();
});

export const onReady = (function () {
//function onReady() {
  /**
    * Connectwise API
    * @typedef {Object} AuthenticationData
    * @property {string} site - the current ConnectWise URL instance.
    * @property {string} companyID - the current ConnectWise company.
    * @property {string} memberID - the current logged in member.
    * @property {string} memberHash - confirms the currently logged in member with the ConnectWise API.
    * @property {string} memberContext - the currently logged in context of the member and must be passed 
    * along with the memberHash when calling the ConnectWise API if using memberHash authentication.
    * @property {string} memberEmail - the current logged in member primary email.
    * @property {string} ssoAccessToken - the provided sso access token.
   * 
  */

  connectWiseClientApi = new ConnectWiseHostedAPI("*",
    {
      "eventHandlers": [
        { "event": "onLoad", "callback": loadAuth },
      ]
    }
    , true);    

  function loadAuth() {    
    connectWiseClientApi.post({ "request": "getMemberAuthentication" },
      /**
       * Store authentication data
       * @param {AuthenticationData} authenticationData - authentication data
       **/
      function (authenticationData) {
        console.log("Hitting authenticationData function",authenticationData.ssoAccessToken);
        connectWiseClientApi.post({"request" : "refreshssoaccess"}, function(data) {  
          let newToken=data["ssoAccessToken"];            
          newToken= (newToken.startsWith('"') && newToken.endsWith('"'))?newToken.slice(1,-1):newToken;
          if(newToken !== authenticationData.ssoAccessToken && newToken !== undefined){
            window.sessionStorage.removeItem('ssoAccessToken');
            window.sessionStorage.setItem('ssoAccessToken', newToken);            
          } else {
            window.sessionStorage.setItem('ssoAccessToken', authenticationData.ssoAccessToken);            
          }       
        }); 
        //window.sessionStorage.setItem('ssoAccessToken', authenticationData.ssoAccessToken);        
        window.sessionStorage.setItem('companyId', authenticationData.companyid);
        window.sessionStorage.setItem('memberId', authenticationData.memberid);
        window.dispatchEvent(new Event('storage'));  
      }
    );
  };

  onReady.prototype.refreshToken = () => {
    let newToken;
    let newArrToken=[];
      connectWiseClientApi.post({"request" : "refreshssoaccess"}, function(data) {      
      newToken=data["ssoAccessToken"];     
      newToken= (newToken.startsWith('"') && newToken.endsWith('"'))?newToken.slice(1,-1):newToken;      
      newArrToken.push(newToken);
      window.sessionStorage.setItem('ssoAccessToken', newToken);      
    });
    console.log("newArrToken",newArrToken);
    return newArrToken;  
  } 
});


